<template>
  <div class="chat-content-quote d-flex w-100" :class="{ 'robotDialogue': sentenceSpliter.length < 30 && selectedStep === 1 && !isFinishTyped, 'typing_height': sentenceSpliter.length > 29 && selectedStep === 1 && !isFinishTyped }">
    <div class="pr-2 chat-content-quote-img"><img src="@/assets/image/ai/robat_icon.png" class=""></div>
    <div v-if="!isSelectedType && !itemData.isLoading">
      <div class="chat-content-quote-text p-3" v-if="isOpenSubtitle">
        <div class="results_en">
          <p v-if="isBegin && selectedStep === 0">{{ itemData.text }}</p>
          <vue-typed-js v-if="!isBegin && !isFinishTyped && selectedStep === 1" :smartBackspace="true" :typeSpeed="50" :showCursor="isShowCursor" @onComplete="onComplete()" :strings="[itemData.text.replace(/(?:\r\n|\r|\n)/g, '<br/>')]">
            <p class="typing"></p>
          </vue-typed-js>
          <p v-if="!isBegin && isFinishTyped && selectedStep === 1" class="m-0 p-0"><span class="m-0 p-0" v-for="(item, index) in sentenceSpliter" :key="index" :class="{ 'active': dictIdx === index, 'pointer isDictionary': isDictionary && !isRegular(item) }" @click.stop="setIdxEn(item, index)">{{ item }}<WordDict v-click-outside="onClickOutside" class="d-none d-lg-block" v-if="dictIdx === index" v-on:closeDict="closeDict" :content="ductWord"/></span></p>
          <p v-if="!isBegin && selectedStep === 0" v-html="tidyText"></p>
        </div>
        <div class="d-flex flex-wrap" v-if="itemData.options">
          <div class="p-1 option-item pointer" v-for="(item, index) in itemData.options" :key="index">
            <div class="p-1 bg-white options-btn" @click="selectAnswer(item)">
              <div class="option-item-inner text-sm">{{ item.name }}</div>
              <div class="option-item-inner text-xs">{{ item.name_en }}</div>
            </div>
          </div>
        </div>

        <div v-if="!isBegin && selectedStep === 1 && isDictionaryMode && itemData.translate" class="translate-mode pt-2 mt-2">
          <p v-html="itemData.translate.replace(/(?:\r\n|\r|\n)/g, '<br/>')" class="font-variant-east-asian-traditional"></p>
        </div>

        <recordPractice v-if="!isBegin && selectedStep === 0" :itemIdx="itemIdx" :ttsUrl="itemData.ttsUrl" />

      </div>
      <div :class="{ 'quote-bg p-3': !isOpenSubtitle }" class="robot-btns d-flex align-items-center mt-2" v-if="!isBegin && selectedStep === 1 && (itemData.ttsUrl || itemData.translate)">
        <chatPractice v-if="itemData.ttsUrl" :itemIdx="itemIdx" :ttsUrl="itemData.ttsUrl" />

        <a v-if="isOpenSubtitle" @click="isDictionaryMode = !isDictionaryMode && itemData.translate" class="tooltip-outer d-flex justify-content-center align-items-center pointer">
          <img v-if="!isDictionaryMode" src="@/assets/image/ai/btn_AItalk_CN.png">
          <img v-if="isDictionaryMode" src="@/assets/image/ai/btn_AItalk_CN_active_perple.png">
          <span class="tooltiptext">中文翻譯</span>
        </a>
      </div>
    </div>
    <div v-if="!isSelectedType && itemData.isLoading">
      <div class="chat-content-quote-text p-3">
        <div><img src="@/assets/image/ai/loading.gif" class=""></div>
      </div>
    </div>
    <div v-if="isSelectedType">
      <div class="chat-content-quote-text p-3">
        <div>
          <p v-if="selectedAns && selectedAns2">你已選擇：{{ selectedAns.name }}>{{ selectedAns2.name }}</p>
        </div>
        <div class="d-flex">
          <!-- <div class="p-1 pointer text-center flex-1" @click="selectType('record')">
            <div class="option-item-inner bg-white p-1 text-sm options-btn">跟讀練習<br/><span class="text-xs">Shadowing Practice</span></div>
          </div> -->
          <div class="p-1 pointer text-center flex-1" @click="selectType('practice')">
            <div class="option-item-inner bg-white p-1 text-sm options-btn">開始對話練習<br/><span class="text-xs">Conversation</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recordPractice from '@/components/AiHelper/chat/recordPractice'
import chatPractice from '@/components/AiHelper/chat/chatPractice'
import WordDict from '@/components/AiHelper/WordDict'
import dictApi from '@/store/library/api'
import Bus from '@/components/AiHelper/bus.js'
import { mapGetters } from 'vuex'
export default {
  name: 'robotDialogue',
  props: {
    itemIdx: {
      type: Number
    },
    itemData: {
      type: Object
    },
    selectedStep: {
      type: Number
    },
    isBegin: {
      type: Boolean
    },
    isSelectedType: {
      type: Boolean,
      default: false
    },
    selectedAns: {
      type: Object
    },
    selectedAns2: {
      type: Object
    },
    chatHistory: {
      type: Object
    }
  },
  components: {
    recordPractice,
    chatPractice,
    WordDict
  },
  data () {
    return {
      ttsUrlSelf: null,
      isRocord: false,
      mediaRecorder: null,
      audioData: [],

      isDictionaryMode: false,
      isShowCursor: true,

      selectedAns_en: null,
      selectedAns2_en: null,

      ductWord: null,
      dictIdx: -1,
      isDictionary: true,
      isFinishTyped: false
    }
  },
  computed: {
    ...mapGetters([
      'isOpenSubtitle'
    ]),
    tidyText () {
      if (this.selectedStep === 0 && this.itemData) {
        return this.itemData.text.replace(/(?:\r\n|\r|\n)/g, '<br/>')
      } else if (this.selectedStep === 1 && this.itemData) {
        return this.itemData.text.replace(/(?:\r\n|\r|\n)/g, '')
      }
      return ''
    },
    sentenceSpliter () {
      let self = this
      if (self.tidyText) {
        const str = self.tidyText
        let pattern = /([\n\s\p{P}])/u
        const splitStr = str.split(pattern)
        return splitStr
      }
      return []
    }
  },
  watch: {
  },
  mounted () {
    this.getBus()
  },
  methods: {
    onClickOutside () {
      this.closeDict()
    },

    selectAnswer (item) {
      let self = this
      self.$emit('selectAnswer', item)
    },

    selectType (type) {
      let self = this
      self.$emit('selectType', type)
    },

    onComplete () {
      let self = this
      $('.typed-cursor').hide()
      self.$nextTick(() => {
        self.isFinishTyped = true
        self.$emit('endTyped')
      })
    },

    closeDict () {
      let self = this
      self.ductWord = null
      self.dictIdx = -1
      Bus.$emit('getDickData', null)
    },

    async setIdxEn (item, idx) {
      let self = this

      if (self.isDictionary && !self.isRegular(item)) {
        const options = { headers: {'showLoading': false }}
        const { data } = await dictApi.get(`/v1/livelibrary/Dict/Word/${item}`, options)
        self.dictIdx = idx
        self.ductWord = data.data

        Bus.$emit('getDickData', { dictIdx: idx, ductWord: data.data})
      }
    },

    isRegular (val) {
      // eslint-disable-next-line no-useless-escape
      let pattern = new RegExp(/[\p{P}\s]/u)
      if (val && val.match(pattern)) {
        return true
      }
      return false
    },
    getBus () {
      let self = this
      Bus.$on('closeDickALL', () => {
        self.closeDict()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .robotDialogue {
    min-height: 104px;
  }
  .typing_height {
    min-height: 128px;
  }
  .results_en {
    p {
      white-space: pre-line;
      word-break: normal;
      margin-bottom: 0;
      span {
        position: relative;
      }
      .isDictionary {
        &:hover {
          background-color: #BBDDFF;
        }
      }
      .active {
        background-color: #BBDDFF;
      }
    }
  }
  .chat-content-quote {
    font-weight: 700;
    max-width: 65%;
    width: 100%;
    @media (max-width: 1024px) {
      max-width: 85%;
    }
    @media (max-width: 768px) {
      max-width: 95%;
    }
    .options-btn {
      border: 2px solid #ffffff;
      border-radius: 8px;
      &:hover {
        border: 2px solid #2A86E0;
      }
    }
    .chat-content-quote-img {
      img {
        max-width: 40px;
      }
    }
    .chat-content-quote-text {
      background-color: #EBF1FC;
      border-bottom-left-radius: 20px 20px;
      border-bottom-right-radius: 20px 20px;
      border-top-right-radius: 20px 20px;
      .option-item {
        text-align: center;
      }
      .parctice-btns {
        a {
          background-color: #2A86E0;
          color: white;
          border-radius: 8px;
          &:hover {
            text-decoration: none !important;
          }
          img {
            height: 22px;
            width: auto;
          }
        }
      }
      .translate-mode {
        border-top: 1px dotted #5A5D9B;
        color: #5A5D9B;
        // border-style: dotted;
      }
    }
  }

  .vue-audio-recorder {
    background: #2A86E0;
    background-image: url('~@/assets/image/ai/microphone.png');
    background-repeat: no-repeat;
    background-position: center left 10px;
    width: 108px;
    height: 40px;
    border-radius: 8px;
    background-size: 20px;
    span {
      display: none !important;
    }
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  .quote-bg {
    background-color: #EBF1FC;
    border-bottom-left-radius: 20px 20px;
    border-bottom-right-radius: 20px 20px;
    border-top-right-radius: 20px 20px;
  }
</style>
