<template>
  <div class="w-100 d-flex aiSuggestDialogue">
    <div class="chat-content-quote d-flex align-items-start d-flex-1">
      <div class="chat-content-quote-text p-3 results_en order-1 d-flex-1 bg-white" >
        <div class="zh results_zh" v-if="!inputResult">
          <textarea ref="textarea" v-if="!isLoadingReplySuggest" v-model="inputVal" class="zh results_zh h-auto w-100 d-inline-block" id="translate_textarea" rows="2" placeholder="請在此輸入您想翻譯的中文。"></textarea>
          <div v-if="isLoadingReplySuggest"><img src="@/assets/image/ai/loading.gif" alt=""></div>
        </div>

        <div class="en results_en" v-if="inputResult" @click.prevent="copy_text_selection(inputResult)">
          <p class="m-0 p-0">{{ inputResult }}</p>
          <!-- <div v-if="isLoadingTranslate" class="tips-suggestion mt-2 pt-2"><img src="@/assets/image/ai/loading.gif" alt=""></div> -->
        </div>
        <div class="zh results_zh">
          <!-- <p class="m-0 p-0 tips-suggestion mt-2 pt-2" v-if="isTranslate && translate && !isLoadingTranslate">{{ translate }}</p> -->
          <p class="m-0 p-0 tips-suggestion mt-2 pt-2" v-if="isTranslate">{{ inputVal }}</p>
          <div v-if="isLoadingTranslate" class="tips-suggestion mt-2 pt-2"><img src="@/assets/image/ai/loading.gif" alt=""></div>
        </div>
      </div>
      <div class="robot-btns d-flex flex-column flex-xl-row align-items-center d-xl-align-items-start mx-2 justify-content-end order-2 my-1 md:my-0">
        <!-- 尚未翻譯 -->
        <a class="tooltip-outer d-flex justify-content-center align-items-center pointer m-1" @click="translateText()" v-if="!inputResult">
          <img src="@/assets/image/ai/btn_translate_btn.png">
          <!-- <span class="tooltiptext">{{ $t('hover.refresh') }}</span> -->
        </a>
        <!-- 翻譯完成 -->
        <a class="tooltip-outer d-flex justify-content-center align-items-center m-1 pointer" v-if="inputResult">
          <div class="relative" v-if="!ttsUrl && isLoadingTTS">
            <img src="@/assets/image/ai/btn_AItalk_play_loading_circle.png">
            <img src="@/assets/image/ai/loading_circle.png" class="position-absolute loading_circle">
          </div>
          <img src="@/assets/image/ai/btn_AItalk_play_dark.png" v-if="ttsUrl && isPlayingAudio" @click="audio_stop()">
          <img src="@/assets/image/ai/btn_AItalk_play_perple.png" v-if="(ttsUrl && !isPlayingAudio) || (!ttsUrl && !isLoadingTTS)" @click="toggleTTS()">
          <span class="tooltiptext">播放原音</span>
        </a>
        <a class="tooltip-outer d-flex justify-content-center align-items-center m-1 pointer font-variant-east-asian-traditional" @click="isTranslate = !isTranslate" v-if="inputResult">
          <img src="@/assets/image/ai/btn_AItalk_CN_perple.png" v-if="!isTranslate">
          <img src="@/assets/image/ai/btn_AItalk_CN_active_perple.png" v-if="isTranslate">
          <span class="tooltiptext">中文翻譯</span>
        </a>
        <a class="tooltip-outer d-flex justify-content-center align-items-center pointer m-1" @click="retrieveData()" v-if="inputResult">
          <img src="@/assets/image/ai/btn_AItalk_Re.png">
          <span class="tooltiptext">重新整理</span>
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/store/api'
import allJs from '@/components/AiHelper/all.js'
export default {
  name: 'TranslateDialogue',
  props: {
    session_id: {
      type: String
    },
    lastChatResult: {
      type: String
    },
    theme: {
      type: String
    },
    context: {
      type: String
    },
    context2: {
      type: String
    },
    recordArray: {
      type: Object
    }
  },
  mixins: [
    allJs
  ],
  components: {
  },
  data () {
    return {
      inputVal: null,
      inputResult: null,

      ttsUrl: null,
      // translate: null,

      isTranslate: false,

      sound_effect: null,

      // status
      isLoadingReplySuggest: false,
      isLoadingTranslate: false,
      isLoadingTTS: false,
      isPlayingAudio: false
    }
  },
  watch: {
    'playbackRate': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.audio_setRate()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'playbackRate'
    ])
  },
  mounted () {
    this.$refs.textarea.focus()
  },
  methods: {
    translateText () {
      let self = this

      let path = `/ai/generate/translate/to/en`

      if (self.inputVal) {
        self.isLoadingReplySuggest = true

        self.$data.ttsUrl = self.$options.data().ttsUrl
        self.$data.inputResult = self.$options.data().inputResult

        api.post(path, {
          content: self.inputVal,
          theme: self.theme
        })
        .then((res) => {
          self.inputResult = res.data.result

          self.isLoadingReplySuggest = false
        })
        .catch(() => {
          self.isLoadingReplySuggest = false
        })
      }


    },

    onClickOutside () {
    },

    retrieveData () {
      let self = this

      self.isTranslate = false

      self.$data.inputVal = self.$options.data().inputVal
      self.$data.inputResult = self.$options.data().inputResult
      self.$data.ttsUrl = self.$options.data().ttsUrl
    },

    async toggleTranslate () {
      let self = this
      self.isTranslate = !self.isTranslate
    },

    async toggleTTS () {
      let self = this

      if (!self.ttsUrl) {
        const myName1 = () => {
          return new Promise(async(resolve) => {
            self.isLoadingTTS = true
            self.ttsUrl = await self.getTTSUrl('female', self.inputResult)
            if (self.ttsUrl) {
              resolve('Ray1')
            }
          })
        }

        Promise.race([myName1()])
        .then(() => {
          self.sound_effect = new Audio(self.ttsUrl)
        })
        .then(() => {
          self.sound_effect.playbackRate = self.playbackRate
          self.audio_play()
          self.isLoadingTTS = false
        })
        .then(() => {
          self.audio_play()
        })
      } else {
        self.audio_play()
      }
    },

    audio_play () {
      let self = this
      self.isPlayingAudio = true
      self.sound_effect.play()
      self.sound_effect.addEventListener('ended', () => {
        self.audio_stop()
      })
    },
    audio_stop () {
      let self = this
      self.isPlayingAudio = false
      self.sound_effect.pause()
      self.sound_effect.currentTime = 0
    },
    audio_setRate () {
      let self = this
      self.sound_effect ? self.sound_effect.playbackRate = self.playbackRate : null
    }
  }
}
</script>
<style lang="scss" scoped>
  textarea {
    border: none !important;
    &:focus {
      outline: none !important;
    }
  }
  .aiSuggestDialogue {
    min-height: 152px;
  }
  .loading_circle {
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    animation: spin 2s infinite linear;
    transform-origin: center;
  }
  @keyframes spin  {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }
    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
  .results_en {
    p {
      white-space: pre-line;
      word-break: normal;
      margin-bottom: 0;
      span {
        position: relative;
      }
      .dict_chat {
        bottom: 100% !important;
        top: auto !important;
      }
      .isDictionary {
        &:hover {
          background-color: #BBDDFF;
        }
      }
      .active {
        background-color: #BBDDFF;
      }
    }
  }
  .chat-content-quote {
    max-width: 70%;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
    .chat-content-quote-text {
      // color: #5A5D9B;
      border: 1px solid #5A5D9B;
      border-bottom-left-radius: 20px 20px;
      border-bottom-right-radius: 20px 20px;
      border-top-right-radius: 20px 20px;
      .option-item {
        width: 25%;
        text-align: center;
      }
      .tips-suggestion {
        border-top: 1px solid #5A5D9B;
        border-style: dashed none none;
        color: #5A5D9B;
      }
    }
  }
</style>
