<template>
  <a class="speechRecognition-button mr-2 pointer d-block position-relative tooltip-outer" v-if="!isBigger" :class="{ 'onreconation_icon': isRecognition, 'opacity-50': remainPoints < 1 }">
    <img src="@/assets/image/ai/mic_C.png" alt="" class="w-100 h-auto d-inline-block" v-if="!isRocording && isSupport && !isRecognition" @click="startRecord">
    <canvas id="canvas" ref="record" @click="stopRecord" v-if="isRocording && isSupport && !isRecognition"></canvas>
    <img src="@/assets/image/ai/mic_C_onreconation.png" alt="w-100 h-auto d-inline-block" class="" v-if="!isRocording && isSupport && isRecognition" @click="stopRecord">
    <img src="@/assets/image/ai/support.png" alt="w-100 h-auto d-inline-block" class="" v-if="!isSupport">
    <span class="tooltiptext">開始/停止錄音</span>
  </a>
</template>

<script>
  import api from '@/store/api'
  import Recorder from 'js-audio-recorder'
  import { mapGetters } from 'vuex'
  let recorder = new Recorder()
  export default {
    name: "SpeechRecognition",
    props: {
      isBigger: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {

        // 支援錄音
        isSupport: true,
        // 錄音中
        isRocording: false,
        // 辨識中
        isRecognition: false,

        mediaRecorder: null,
        audioData: [],

        drawRecordId: null,

        count: 16,
        timer: null
      }
    },
    computed: {
      ...mapGetters([
        'remainPoints'
      ])
    },
    mounted () {
    },
    destroy () {
      recorder.destroy().then(() => {
        recorder = null
      })
    },
    methods: {
      swal_failPopPoints (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        title: 'Notice',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'remainPoints_swal my-3',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },
      getPermission () {
        let self = this
        recorder.getPermission().then(() => {
          self.isSupport = true
        }, (error) => {
          self.isSupport = false
          console.log(error)
        })
      },

      startRecord () {
        let self = this

        if (self.remainPoints > 0) {
          let time = 0

          recorder.start().then(() => {
            self.countDown()
            self.isRocording = true
            self.drawRecord()
          }, (error) => {
            console.log(error)
            self.isRocording = false
          })

          recorder.onprogress = (params) => {
            if (params.vol <= 10) {
              time++
            } else {
              time = 0
            }

            if (time >= 35) {
              self.stopRecord()
            }
          }
        } else {
          self.swal_failPopPoints('點數不足')
        }
      },

      stopRecord () {
        let self = this

        recorder.stop()
        self.isRocording = false

        self.audioData = recorder.getWAVBlob()

        self.getSTT(recorder.getWAVBlob())

        clearInterval(self.timer)
        self.$emit('speechCountDown', { isSpeechRecognition: false, count: 16 })
        self.count = 16
      },

      getSTT (audioData) {
        let self = this

        let path = `/ai/stt`

        let formData = new FormData()
        formData.append('file', audioData)
        // formData.append('ref_text', '')

        self.isRecognition = true

        api.post(path, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'showLoading': false
            }
          })
          .then((response) => {
            self.$store.dispatch('getRemainPoints')
            self.$emit('emitSpeechRecognition', response.data)

            self.isRecognition = false
          })
      },

      drawRecord () {
        let self = this

        self.drawRecordId = requestAnimationFrame(self.drawRecord)
        self.drawWave({
          canvas: self.$refs.record,
          dataArray: recorder.getRecordAnalyseData(),
          bgcolor: 'rgb(200, 200, 200, 0)',
          lineWidth: 1,
          lineColor: 'rgb(255, 255, 255)'
        })
      },

      drawWave({
        canvas,
        dataArray,
        bgcolor = 'rgb(200, 200, 200, 0)',
        lineWidth = 2,
        lineColor = 'rgb(0, 0, 0)'
      }) {
        if (!canvas) return
        canvas.width = 54;
        canvas.height = 54;

        let background = new Image()
        background.src = require('@/assets/image/ai/mic_C_onrecord.png')

        const ctx = canvas.getContext('2d')
        const bufferLength = dataArray.length
        const sliceWidth = canvas.width / bufferLength
        let x = 0
        ctx.drawImage(background, 0, 0)
        ctx.fillStyle = bgcolor
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        ctx.lineWidth = lineWidth
        ctx.strokeStyle = lineColor

        ctx.beginPath()

        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128
          const y = (v * canvas.height) / 2

          if (i === 0) {
            ctx.moveTo(x, y)
          } else {
            ctx.lineTo(x, y)
          }
          x += sliceWidth
        }

        ctx.lineTo(canvas.width, canvas.height / 2)
        ctx.stroke()
      },

      countDown () {
        let self = this
        self.timer = setInterval(() => {
            self.count--
            self.$emit('speechCountDown', { isSpeechRecognition: true, count: self.count })

            if (self.count === -1) {
              self.stopRecord()
              clearInterval(self.timer)
              self.count = 16
              self.$emit('speechCountDown', { isSpeechRecognition: false, count: 16 })
            }
          }, 1000
        )
      },
      swal_failPopUp (text) {
        let self = this
        let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
        self.$swal.fire({
          html: defaultTitle,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: `確認`,
          customClass: {
            container: '',
            popup: 'p-0',
            header: 'swal-header',
            closeButton: '',
            htmlContainer: 'htmlContainer my-3',
            actions: 'mb-4 mt-5',
            confirmButton: 'btn-submit',
            cancelButton: 'bg-red-main-ai',
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .speechRecognition-button {
    width: 3rem !important;
    height: 3rem !important;
    display: inline-block !important;
    margin-right: .25rem !important;
  }
  .microphone-btn {
    background-color: #5A5D9B;
    padding: .8rem 6.5rem;
    border-radius: 53px;
    img {
      height: 26px;
      width: auto;
    }
  }
  .onreconation_icon {
    &:after {
      position: absolute;
      content: '';
      background-image: url('~@/assets/image/ai/mic_loading.png');
      background-position: center;
      background-size: contain;
      width: 38px;
      height: 38px;
      z-index: 2;
      left: 15%;
      top: 15%;
      transform: translate(-50%, -50%);
      animation: move 2s linear infinite;
      transform-origin: center;
      display: block;
    }
  }
  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  // hint
.tooltip-outer {
  position: relative;
  .tooltiptext {
    visibility: hidden;
    min-width: 40px;
    background-color: #0E103E;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.2em 0.6em;
    position: absolute;
    z-index: 9999;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    white-space: nowrap;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      // margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #0F455C transparent transparent transparent;
      z-index: 9;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}
</style>