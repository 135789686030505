<template>
  <a class="d-flex justify-content-center align-items-center mr-1 pointer hover-item tooltip-outer" @click="audio_play()">
    <img src="@/assets/image/ai/btn_AItalk_voice.png" class="mr-1 off">
    <img src="@/assets/image/ai/btn_AItalk_voice_active.png" class="mr-1 on">
    <span class="tooltiptext">我的錄音</span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UserVoice',
  props: {
    ttsUrl: {
      type: String
    }
  },
  data () {
    return {
      sound_effect: new Audio(this.ttsUrl)
    }
  },
  computed: {
    ...mapGetters([
      'playbackRate'
    ])
  },
  watch: {
    'playbackRate': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.audio_setRate()
        }
      },
      immediate: true
    }
  },
  mounted () {
  },
  methods: {
    audio_play () {
      // this.isPlayingAudio = true
      this.sound_effect.play()
      this.sound_effect.playbackRate = this.playbackRate
      this.sound_effect.addEventListener('ended', () => {
        this.audio_stop()
      })
    },
    audio_stop () {
      // this.isPlayingAudio = false
      this.sound_effect.pause()
      this.sound_effect.currentTime = 0
    },
    audio_setRate () {
      let self = this
      self.sound_effect ? self.sound_effect.playbackRate = self.playbackRate : null
    }
  }
}
</script>
<style lang="scss" scoped>
  .loading_circle {
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    animation: spin 2s infinite linear;
    transform-origin: center;
  }
  @keyframes spin  {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }
    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
  .opacity-50{
    opacity: 0.5;
  }
  .hover-item {
    .on {
      display: none;
    }
    &:hover {
      .off {
        display: none;
      }
      .on {
        display: block;
      }
    }
  }
</style>
