<template>
  <div class="w-100 d-flex">
    <div class="chat-content-quote d-flex align-items-start">
      <div class="chat-content-quote-text p-3" @click.prevent="copy_text_selection(replySuggestions)">
        <div class="en results_en">
          <p v-if="!isLoadingReplySuggest" class="m-0 p-0"><span class="m-0 p-0" v-for="(item, index) in sentenceSpliter" :key="index" :class="{ 'active': dictIdx === index, 'pointer isDictionary': isDictionary && !isRegular(item) }" @click.stop="setIdxEn(item, index)">{{ item }}<WordDict v-click-outside="onClickOutside" class="d-none d-lg-block dict_chat" v-if="dictIdx === index" v-on:closeDict="closeDict" :content="ductWord"/></span></p>
          <div v-if="isLoadingReplySuggest"><img src="@/assets/image/ai/loading.gif" alt=""></div>
        </div>

        <div class="zh results_zh">
          <p class="m-0 p-0 tips-suggestion mt-2 pt-2" v-if="isTranslate && translate && !isLoadingTranslate">{{ translate }}</p>
          <div v-if="isLoadingTranslate" class="tips-suggestion mt-2 pt-2"><img src="@/assets/image/ai/loading.gif" alt=""></div>
        </div>
      </div>
      <div class="robot-btns d-flex flex-column flex-lg-row align-items-center d-xl-align-items-start justify-content-end  order-2 my-1 d-lg-my-0 mx-2">
        <a class="tooltip-outer d-flex justify-content-center align-items-center m-1 pointer">
          <div class="position-relative" v-if="!ttsUrl && isLoadingTTS">
            <img src="@/assets/image/ai/btn_AItalk_play_loading_circle.png">
            <img src="@/assets/image/ai/loading_circle.png" class="position-absolute loading_circle">
          </div>
          <img src="@/assets/image/ai/btn_AItalk_play_dark.png" v-if="ttsUrl && isPlayingAudio" @click="audio_stop()">
          <img src="@/assets/image/ai/btn_AItalk_play_perple.png" v-if="(ttsUrl && !isPlayingAudio) || (!ttsUrl && !isLoadingTTS)" @click="toggleTTS()">
          <span class="tooltiptext">播放原音</span>
        </a>
        <a class="tooltip-outer d-flex justify-content-center align-items-center m-1 pointer font-variant-east-asian-traditional" @click="toggleTranslate()">
          <img src="@/assets/image/ai/btn_AItalk_CN_perple.png" v-if="!isTranslate">
          <img src="@/assets/image/ai/btn_AItalk_CN_active_perple.png" v-if="isTranslate">
          <span class="tooltiptext">中文翻譯</span>
        </a>
        <a v-if="replySuggestions" class="tooltip-outer d-flex justify-content-center align-items-center pointer m-1" @click="retrieveData()">
          <img src="@/assets/image/ai/btn_AItalk_Re.png">
          <span class="tooltiptext">重新整理</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/api'
import dictApi from '@/store/library/api'
import WordDict from '@/components/AiHelper/WordDict'
import Bus from '@/components/AiHelper/bus.js'
import allJs from '@/components/AiHelper/all.js'
import { mapGetters } from 'vuex'
export default {
  name: 'aiSuggestDialogue',
  props: {
    session_id: {
      type: String
    },
    lastChatResult: {
      type: String
    },
    theme: {
      type: String
    },
    context: {
      type: String
    },
    context2: {
      type: String
    }
  },
  components: {
    WordDict
  },
  mixins: [
    allJs
  ],
  data () {
    return {
      ttsUrl: null,
      translate: null,

      isDictionary: true,
      isTranslate: false,

      ductWord: null,
      dictIdx: -1,

      replySuggestions: null,

      isLoadingReplySuggest: false,
      isLoadingTranslate: false,

      sound_effect: null,

      isLoadingReplySuggest: false,
      isLoadingTranslate: false,
      isLoadingTTS: false,
      isPlayingAudio: false
    }
  },
  computed: {
    ...mapGetters([
      'remainPoints',
      'playbackRate'
    ]),
    sentenceSpliter () {
      let self = this
      if (self.replySuggestions) {
        const str = self.replySuggestions
        let pattern = /([\n\s\p{P}])/u
        const splitStr = str.split(pattern)
        return splitStr
      }
      return []
    }
  },
  watch: {
    'playbackRate': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.audio_setRate()
        }
      },
      immediate: true
    }
  },
  mounted () {
    !this.replySuggestions ? this.reply_suggestions() : null
    this.getBus()
  },
  methods: {
    swal_failPopPoints (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        title: 'Notice',
        customClass: {
          title: 'text-white',
          container: '',
          popup: 'p-0',
          header: 'swal-header-notice justify-content-center align-items-center',
          closeButton: '',
          htmlContainer: 'remainPoints_swal my-3',
          actions: 'mb-4 mt-2',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },
    onClickOutside () {
      this.closeDict()
    },

    async reply_suggestions () {
      let self = this

      if (self.remainPoints < 1) {
        self.swal_failPopPoints('點數不足')
      } else {
        let path = `/ai/generate/reply_suggestions`

        self.isLoadingReplySuggest = true

        self.ttsUrl = null
        self.translate = null
        const options = { headers: {'showLoading': false }}
        await api.post(path, {
          // content: self.lastChatResult,
          content: '',
          theme: self.theme,
          context: self.context,
          context2: self.context2,
          session: self.session_id
        }, options)
        .then((res) => {
          self.$store.dispatch('getRemainPoints')
          self.replySuggestions = res.data.result

          self.isLoadingReplySuggest = false
        })
        .catch(() => {
          self.isLoadingReplySuggest = false
        })
      }
    },

    closeDict () {
      let self = this
      self.ductWord = null
      self.dictIdx = -1

      Bus.$emit('getDickData', { dictIdx: -1, ductWord: null })
    },

    async setIdxEn (item, idx) {
      let self = this
      if (self.isDictionary && !self.isRegular(item)) {
        const { data } = await dictApi.get(`/v1/livelibrary/Dict/Word/${item}`)
        self.dictIdx = idx
        self.ductWord = data.data

        Bus.$emit('getDickData', { dictIdx: idx, ductWord: data.data })
      }
    },

    isRegular (val) {
      // eslint-disable-next-line no-useless-escape
      let pattern = new RegExp(/[\p{P}\s]/u)
      if (val && val.match(pattern)) {
        return true
      }
      return false
    },

    retrieveData () {
      let self = this
      self.translate = null
      self.ttsUrl = null
      self.isTranslate = false

      this.reply_suggestions()
    },

    async toggleTranslate () {
      let self = this
      self.isTranslate = !self.isTranslate

      if (!self.translate) {
        self.isLoadingTranslate = true
        self.translate = await self.get_Translate_Dialog(self.theme, self.context, self.replySuggestions)
        self.$store.dispatch('getRemainPoints')
      }

      if (self.translate) {
        self.isLoadingTranslate = false
      }
    },

    async toggleTTS () {
      let self = this

      if (!self.ttsUrl) {
        const myName1 = () => {
          return new Promise(async(resolve) => {
            self.isLoadingTTS = true
            self.ttsUrl = await self.getTTSUrl('female', self.replySuggestions)
            if (self.ttsUrl) {
              resolve('Ray1')
            }
          })
        }

        Promise.race([myName1()])
        .then(() => {
          self.sound_effect = new Audio(self.ttsUrl)
        })
        .then(() => {
          self.sound_effect.playbackRate = self.playbackRate
          self.audio_play()
          self.isLoadingTTS = false
        })
        .then(() => {
          self.audio_play()
          self.$store.dispatch('getRemainPoints')
        })
      } else {
        self.audio_play()
      }
    },
    audio_play () {
      let self = this
      self.isPlayingAudio = true
      self.sound_effect.play()
      self.sound_effect.addEventListener('ended', () => {
        self.audio_stop()
      })
    },
    audio_stop () {
      let self = this
      self.isPlayingAudio = false
      self.sound_effect.pause()
      self.sound_effect.currentTime = 0
    },
    audio_setRate () {
      let self = this
      self.sound_effect ? self.sound_effect.playbackRate = self.playbackRate : null
    },

    swal_failPopUp (text) {
      let self = this
      let defaultTitle = text ?  `<h3>${text}</h3><p>請洽客服人員加購點數</p>` : `<h3>生成失敗</h3><p>糟糕！發生了一點小失誤，請再給我一次機會！</p>`
      self.$swal.fire({
        html: defaultTitle,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: `確認`,
        customClass: {
          container: '',
          popup: 'p-0',
          header: 'swal-header',
          closeButton: '',
          htmlContainer: 'htmlContainer my-3',
          actions: 'mb-4 mt-5',
          confirmButton: 'btn-submit',
          cancelButton: 'bg-red-main-ai',
        }
      })
    },

    getBus () {
      let self = this
      Bus.$on('closeDickALL', () => {
        self.closeDict()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .aiSuggestDialogue {
    min-height: 152px;
  }
  .loading_circle {
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    animation: spin 2s infinite linear;
    transform-origin: center;
  }
  @keyframes spin  {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }
    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
  .results_en {
    p {
      white-space: pre-line;
      word-break: normal;
      margin-bottom: 0;
      span {
        position: relative;
      }
      .dict_chat {
        bottom: 100% !important;
        top: auto !important;
      }
      .isDictionary {
        &:hover {
          background-color: #BBDDFF;
        }
      }
      .active {
        background-color: #BBDDFF;
      }
    }
  }
  .chat-content-quote {
    max-width: 60%;
    @media (max-width: 1025px) {
      max-width: 100%;
    }
    .chat-content-quote-text {
      color: #5A5D9B;
      border: 1px solid #5A5D9B;
      border-bottom-left-radius: 20px 20px;
      border-bottom-right-radius: 20px 20px;
      border-top-right-radius: 20px 20px;
      .option-item {
        width: 25%;
        text-align: center;
      }
      .tips-suggestion {
        border-top: 1px solid #5A5D9B;
        border-style: dashed none none;
        color: #5A5D9B;
      }
    }
  }
</style>
