<template>
  <div class="settings d-flex">
    <!-- mobile -->
    <a class="d-xl-none m-1 text-sm align-items-center pointer p-1 d-flex justify-content-center align-items-center" @click.prevent="openSetting()">
      <img src="@/assets/image/ai/Setting_line_duotone.png" class="object-contain">
    </a>
    <!-- <a class="d-xl-none m-1 text-sm align-items-center pointer p-1 d-flex justify-content-center align-items-center" @click.prevent="swal_save_check()">
      <img src="@/assets/image/ai/file_save.png" class="object-contain">
    </a> -->

    <!-- web -->
    <a class="tooltip-outer d-none m-1 text-sm screen_text_on d-xl-flex align-items-center pointer px-2 justify-content-center align-items-center" :class="{ 'screen_text_off': rateIdx !== 0 }" @click.prevent="changeRate()">
      <img src="@/assets/image/ai/icon_speed.png" class="mr-1 object-contain" v-if="rateIdx === 0">
      <img src="@/assets/image/ai/icon_speed_off.png" class="mr-1 object-contain" v-if="rateIdx !== 0">
      x{{ rateArr[rateIdx] }}
      <span class="tooltiptext-bottom">播放速度</span>
    </a>

    <a class="tooltip-outer d-none m-1 text-sm screen_text_on d-xl-flex align-items-center pointer px-2 justify-content-center" :class="{ 'screen_text_off': !isOpenSubtitle }" @click="toggleSubtitle">
      <img src="@/assets/image/ai/icon_text.png" v-if="isOpenSubtitle" class="mr-1 object-contain">
      <img src="@/assets/image/ai/icon_text_off.png" v-if="!isOpenSubtitle" class="mr-1 object-contain">
      {{ isOpenSubtitle ? 'on' : 'off' }}
      <span class="tooltiptext-bottom">文字顯示</span>
    </a>
    <!-- <a class="d-none m-1 text-sm save_conversation d-xl-flex align-items-center pointer px-3 justify-content-center" @click="swal_save_check()">
      <img src="@/assets/image/ai/file_save.png" class="mr-1">save
    </a> -->
  </div>

</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Settings',
    props: {
      selectedAns: {
        type: Object
      },
      selectedAns2: {
        type: Object
      }
    },
    data () {
      return {
        rateArr: ['1.0', '1.25', '0.75'],
        rateIdx: 0,
      }
    },
    computed: {
      ...mapGetters([
        'playbackRate',
        'isOpenSubtitle'
      ])
    },
    mounted () {
    },
    methods: {
      changeRate () {
        let self = this
        if (self.rateIdx === 0) {
          self.rateIdx = 1
        } else if (self.rateIdx === 1) {
          self.rateIdx = 2
        } else if (self.rateIdx === 2) {
          self.rateIdx = 0
        }

        self.$store.dispatch('setPlayBackRate', Number(self.rateArr[self.rateIdx]))
      },
      toggleSubtitle () {
        this.$store.dispatch('toggleSubtitle', !this.isOpenSubtitle)
      },
      swal_save_check () {
        let self = this
        let defaultTitle = `<h4>請問您是否要儲存本次對話記錄，對話記錄儲存後可以再次開啟對話進行練習，並且觀看本次對話成果。</h4><h5>2024/06/24 10:07:40    餐廳>點餐 / 女聲 / 英國腔</h5><p class="text-base">備註：每組對話紀錄為唯一資料，若您想要以相同的對話主題，進行不同的口說練習，請按重新開始，開啟新的對話。</p>`
        self.$swal.fire({
          html: defaultTitle,
          showCloseButton: false,
          showDenyButton: true,
          focusConfirm: true,
          confirmButtonText: `確認`,
          denyButtonText: `取消`,
          title: '儲存確認',
          customClass: {
            title: 'text-white',
            container: '',
            popup: 'p-0',
            header: 'swal-header-notice justify-content-center align-items-center',
            closeButton: '',
            htmlContainer: 'save_check my-4',
            actions: 'mb-4 mt-2',
            confirmButton: 'btn-submit',
            denyButton: 'btn-deny order_1'
          }
        })
        .then((result) => {
            if (result.isConfirmed === true) {
              self.swal_save_success()
            }
          }
        )
      },
      swal_save_success () {
        let self = this
        let defaultTitle = `<h4>儲存成功</h4><h5>2024/06/24 10:07:40    餐廳>點餐 / 女聲 / 英國腔</h5><p class="text-base">備註：每組對話紀錄為唯一資料，若您想要以相同的對話主題，進行不同的口說練習，請按重新開始，開啟新的對話。</p>`
        self.$swal.fire({
          html: defaultTitle,
          showCloseButton: false,
          showDenyButton: true,
          focusConfirm: true,
          confirmButtonText: `查看紀錄評分`,
          denyButtonText: `繼續聊天`,
          customClass: {
            title: 'text-white',
            container: '',
            popup: 'p-0',
            header: 'swal-header-success justify-content-center align-items-center',
            closeButton: '',
            htmlContainer: 'save_check my-4',
            actions: 'mb-4 mt-2',
            confirmButton: 'btn-submit',
            denyButton: 'btn-deny order_1'
          }
        })
        .then((result) => {
            if (result.isConfirmed === true) {
              
            }
          }
        )
      },
      async openSetting () {
        let self = this
        let playbackRate = self.playbackRate
        let isOpenSubtitle = self.isOpenSubtitle
        let defaultTitle = `<p class="text-base mb-3">${self.selectedAns.name}>${self.selectedAns2.name} / 女聲 / 英國腔</p>` +
          `<div class="row mb-3">
              <div class="col-4 font-bold text-right">語速</div>
              <div class="col-8">
                <select id="my-select" name="parent" class="form-control">
                  <option value="0.75" ${playbackRate === 0.75 ? 'selected' : ''}>0.75</option>
                  <option value="1" ${playbackRate === 1 ? 'selected' : ''}>1.0</option>
                  <option value="1.25" ${playbackRate === 1.25 ? 'selected' : ''}>1.25</option>
                </select>
              </div>
            </div>` +
            `<div class="row mb-3">
              <div class="col-4 font-bold text-right">文字顯示</div>
              <div class="col-8">
                <select id="my-select2" name="parent2" class="form-control" v-model="${isOpenSubtitle}">
                  <option value="true" ${isOpenSubtitle ? 'selected' : ''}>顯示字幕</option>
                  <option value="false" ${!isOpenSubtitle ? 'selected' : ''}>字幕關閉</option>
                </select>
              </div>
            </div>`

        const { value: formValues } = await self.$swal.fire({
          html: defaultTitle,
          showCloseButton: false,
          showDenyButton: true,
          focusConfirm: true,
          confirmButtonText: `儲存設定`,
          denyButtonText: `取消`,
          title: '聊天設定',
          customClass: {
            title: 'text-white',
            container: '',
            popup: 'p-0',
            header: 'swal-header-notice justify-content-center align-items-center',
            closeButton: '',
            htmlContainer: 'save_check my-4',
            actions: 'mb-4 mt-2',
            confirmButton: 'btn-submit',
            denyButton: 'btn-deny order_1'
          },
          preConfirm: () => {
            return [
              document.getElementById("my-select").value,
              document.getElementById("my-select2").value
            ]
          }
        })
        if (formValues) {
          let idx = self.rateArr.indexOf(formValues[0])
          self.rateIdx = idx

          self.$store.dispatch('setPlayBackRate', Number(self.rateArr[idx]))
          self.$store.dispatch('toggleSubtitle', formValues[1] === 'true' ? true : false)
        }
      }
    }
  }
</script>
<style lang="scss">
.settings {

  .screen_text_on {
    border: 1px solid #5a5d9b;
    color: #5a5d9b;
    padding: .5em 0;
    border-radius: 4px;
    min-width: 82px;
    &:hover {
      text-decoration: none !important;
    }
  }
  .screen_text_off {
    border: 1px solid #5a5d9b;
    color: rgb(255, 255, 255);
    background-color: #5a5d9b;
    padding: .5em 0;
    border-radius: 4px;
    min-width: 82px;
    &:hover {
      text-decoration: none !important;
    }
  }
  .save_conversation {
    border: 1px solid rgba(90, 93, 155, 1);
    color: rgba(90, 93, 155, 1);
    padding: .5em;
    border-radius: 4px;
    &:hover {
      text-decoration: none !important;
    }
  }
  .custom-dropdown-setting select {
    width: 100%;
    color: rgba(90, 93, 155, 1);
    padding: 1em;
    margin: 0;
    border: 1px solid rgba(90, 93, 155, 1);
    text-indent: 0.01px;
    text-overflow: '';
    -webkit-appearance: button;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    // text-align: center;
    border-radius: 4px;
  }

  // .custom-dropdown::after {
  //   position: absolute;
  //   pointer-events: none;
  //   color: #0E103E;
  //   content: "\25BC";
  //   height: 1em;
  //   font-size: 0.625em;
  //   line-height: 1;
  //   right: 1.2em;
  //   top: 40%;
  // }
}
</style>
