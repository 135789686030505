<template>
  <div class="parctice-btns d-flex justify-content-end align-items-center mt-1">

    <audio v-if="ttsUrl" muted id='audio' :src="ttsUrl" controlos="controls" :ref="`playBtnRecords${itemIdx}`"></audio>

    <!-- 播放原音 -->
    <a v-if="ttsUrl" @click.stop="playAudioStandard(`Records${itemIdx}`)" class="d-flex justify-content-center align-items-center mr-2 pointer"><img src="@/assets/image/ai/btn_Play.png"></a>

    <!-- 錄音 -->
    <a v-if="!isRocording" @click="startRecord" class="d-flex justify-content-center align-items-center pointer mr-2"><img src="@/assets/image/ai/btn_M.png"></a>
    <a v-if="isRocording" @click="stopRecord" class="d-flex justify-content-center align-items-center mr-2"><img src="@/assets/image/ai/btn_P.png"></a>

    <!-- 播放錄音 -->
    <a v-if="audioData && !isRocording" @click.stop="playRecord()" class="d-flex justify-content-center align-items-center pointer"><img src="@/assets/image/ai/btn_V.png"></a>

    <span class="count text-sm" v-if="isRocording">{{ count }}s</span>
  </div>
</template>

<script>
import allJs from '@/components/AiHelper/all.js'
import Recorder from 'js-audio-recorder'
let recorder = new Recorder()
export default {
  name: 'RecordPractice',
  props: {
    itemIdx: {
      type: Number
    },
    ttsUrl: {
      type: String
    }
  },
  mixins: [
    allJs
  ],
  data () {
    return {
      isRocording: false,
      isPlaying: false,
      audioData: null,
      count: 5,
      timer: null
    }
  },
  destroy () {
    recorder.destroy().then(() => {
      recorder = null
    })
    clearTimeout(this.timer)
  },
  mounted () {
  },
  methods: {
    startRecord () {
      let self = this
        recorder.start().then(() => {
          self.isRocording = true
          self.countDown()
        }, (error) => {
            console.log(error)
            self.isRocording = false
        })
    },

    stopRecord () {
      let self = this

      recorder.stop()

      self.audioData = recorder.getWAVBlob()
      self.isRocording = false
    },

    playRecord () {
      recorder.play()

      recorder.onstopplay = () => {
        console.log('onstopplay')
      }
    },

    countDown () {
      let self = this
      self.timer = setInterval(() => {
          self.count--

          if (self.count === -1) {
            self.stopRecord()
            clearInterval(self.timer)
            self.count = 5
          }
        }, 1000
      )
    }
  }
}
</script>
<style lang="scss" scoped>
  .parctice-btns {
    a {
      background-color: #2A86E0;
      color: white;
      border-radius: 8px;
      &:hover {
        text-decoration: none !important;
      }
    }
    .count {
      width: 30px;
    }
  }
</style>
