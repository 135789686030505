<template>
  <div class="chat-header d-flex justify-content-between align-items-center bg-white">
    <div class="align-items-center flex-1 d-none d-xl-flex">
      <div class="p-2 pr-3 d-none d-xl-block"><img src="@/assets/image/ai/robat_icon.png" class=""></div>
      <div class="d-none d-xl-block">
        <div class="font-bold">口說練習小幫手</div>
        <div class="text-xs" style="color: #999999;" v-if="selectedAns && selectedAns2">
          {{ selectedAns.name }}>{{ selectedAns2.name }}
        </div>
        <!-- <div class="text-xs" style="color: #999999;">in LiveABC a cute robot</div> -->
      </div>
    </div>
    <!-- - -->
    <div class="flex-1 d-flex align-items-center justify-content-center">
      <p class="font-black text-xl">{{ selectedStepTitle }}</p>
    </div>
    <!-- - -->
    <settings v-if="selectedAns && selectedAns2" class="settings" :selectedAns="selectedAns" :selectedAns2="selectedAns2" />
    <!-- - -->
    <div class="d-flex justify-content-end" :class="{ 'flex-1': selectedStep === 0 }">
      <a class="p-2 py-3 p-xl-2 py-xl-4 refresh-btn text-white w-auto pointer" @click="$emit('reStart')">
        <img src="@/assets/image/ai/coolicon_white.png" class="mr-2 d-inline-block">重新開始</a>
    </div>
  </div>
</template>

<script>
import settings from '@/components/AiHelper/chat/settings'
export default {
  name: 'orally_speaking_header',
  props: {
    isBegin: {
      type: Boolean,
      default: true
    },
    selectedStep: {
      type: Number,
      default: 0
    },
    selectedAns: {
      type: Object
    },
    selectedAns2: {
      type: Object
    }
  },
  data () {
    return {
    }
  },
  components: {
    settings
  },
  computed: {
    selectedStepTitle () {
      let self = this
      if (!self.isBegin && self.selectedStep === 0) {
        return '情境跟讀練習'
      } else if (!self.isBegin && self.selectedStep === 1) {
        return '情境對話練習'
      }
      return '情境選擇'
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  .chat-header {
    border-bottom: 1px solid #5A5D9B;
    .refresh-btn {
      background: #5A5D9B;
      transition: .3s;
      &:hover {
        text-decoration: none !important;
        opacity: .8;
      }
    }
    .settings {
      width: 20%;
      @media (min-width: 1025px) {
        flex: 1 1 0%;
      }
    }
  }
</style>
